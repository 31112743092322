import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import BlogList from "./BlogList";

class MostPopularTemplate extends React.Component {
  render() {
    const { data, slug } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const { edges: videoPageViews } = data.videos;
    const { edges: blogPageViews } = data.blogs;
    let { authors } = data;
    let postData = [];
    let pageViews = [];

    if (slug.indexOf("blog") !== -1) {
      pageViews = blogPageViews;
    } else {
      pageViews = videoPageViews;
    }

    pageViews.forEach(({ node: view }) => {
      posts.forEach(({ node: post }) => {
        if (
          view.path !== slug &&
          view.path !== addTrailingSlash(slug) &&
          view.path !== addTrailingSlash(slug) + "most-popular" &&
          view.path !==
            addTrailingSlash(slug) + addTrailingSlash("most-popular")
        ) {
          if (
            post.fields.slug === view.path ||
            post.fields.slug === addTrailingSlash(view.path)
          ) {
            post.frontmatter.views = view.views;
            postData.push({ node: post });
          }
        }
      });
    });

    // reverse sort by views
    postData = postData.sort((a, b) => (a.views < b.views ? 1 : -1));
    // limit to max of 10 results
    postData = postData.slice(0, 9);
    authors = authors.nodes;
    return (
      <BlogList
        posts={postData}
        authors={authors}
        emptyMessage="We don't have any popular pages yet."
      />
    );
  }
}

MostPopular.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  slug: PropTypes.string,
};

export default function MostPopular(args) {
  return (
    <StaticQuery
      query={graphql`
        query MostPopularPageComponent {
          authors: allMarkdownRemark(
            filter: {
              frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
            }
          ) {
            nodes {
              id
              fields {
                slug
              }
              frontmatter {
                name
              }
            }
          }
          blogs: allPageViews(
            limit: 10
            filter: { path: { regex: "//blog/[^0-9]/" } }
            sort: { fields: views, order: DESC }
          ) {
            edges {
              node {
                views
                path
              }
            }
          }
          videos: allPageViews(
            limit: 10
            filter: { path: { regex: "//video/[^0-9]/" } }
            sort: { fields: views, order: DESC }
          ) {
            edges {
              node {
                views
                path
              }
            }
          }
          allMarkdownRemark(
            filter: {
              fields: { slug: { regex: "//blog|video//" } }
              frontmatter: {
                advanced: { templateKey: { in: ["blog-post", "video-post"] } }
              }
            }
          ) {
            edges {
              node {
                id
                frontmatter {
                  title
                  advanced {
                    templateKey
                  }
                  creationDate
                  author
                  category
                }
                fields {
                  slug
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <MostPopularTemplate data={data} count={count} slug={args.slug} />
      )}
    />
  );
}

function addTrailingSlash(url) {
  if (url.charAt(url.length - 1) !== "/") {
    url = url + "/";
  }
  return url;
}
