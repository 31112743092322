import * as React from "react";
import PropTypes from "prop-types";
import DisplayContentHeader from "./DisplayContentHeader";
import { v4 } from "uuid";
function returnAuthorSlug(name, authors) {
  let k = null;
  let v = null;
  let slugs = [];

  authors.forEach((author) => {
    k = author.frontmatter.name;
    v = author.fields.slug;
    slugs[k] = v;
  });

  if (slugs[name]) {
    return slugs[name];
  } else {
    return "";
  }
}

const BlogList = ({
  posts,
  authors,
  emptyMessage = "We have no matching entries.",
  title = "",
}) => {
  if (posts && posts.length) {
    return (
      <div className="content">
        {title ? <DisplayContentHeader title={title} /> : null}
        <ul className="no-bullets">
          {posts &&
            posts.map(({ node: post }) => (
              <li key={v4()}>
                <DisplayContentHeader
                  title={post.frontmatter.title}
                  slug={post.fields.slug}
                  relatedPerson={post.frontmatter.author}
                  relatedPersonType="Author"
                  relatedPersonSlug={returnAuthorSlug(
                    post.frontmatter.author,
                    authors
                  )}
                  date={post.frontmatter.creationDate}
                  weight="normal"
                />
              </li>
            ))}
        </ul>
      </div>
    );
  } else {
    return <div className="content">{emptyMessage}</div>;
  }
};

BlogList.propTypes = {
  posts: PropTypes.array,
};

export default BlogList;
